body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
}
ul,li{
  padding: 0;
  margin: 0;
  list-style: none;
}
a,a:hover,a:focus,a:active{
  text-decoration: none;
  cursor: pointer;
}
img{
  width: auto;
  max-width: 100%;
}
