.main-footer {
    /* background-color: #F5F5F5; */
    padding: 60px 0 30px;
    background-image: url("../../assets/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -2px 0;
    z-index: 1;
    position: relative;
}
.footer-info {
    text-align: left;
}
.footer-info p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin: 0;
    max-width: 428px;
}
.footer-info:not(.site-info) p {
    max-width: 428px;
}
.footer-info.site-info p {
    max-width: 370px;
}
.widget-title {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.48px;
    margin-bottom: 16px;
}
.footer-info img {
    margin-bottom: 15px;
    max-width: 150px;
}
.footer-links a {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.social-links {
    display: flex;
    align-items: center;
    margin-top: 25px;
}
.social-links li a {
    color: #fff;
    font-size: 26px;
}
.social-links li + li{
    margin-left: 35px;
}
.copyright-text p {
    margin: 0;
    text-align: left;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.copyright-text p a {
    color: #FB9413;
    display: inline-block;
    margin: 0 6px;
}
.copyright-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.copyright-links li + li{
    margin-left: 20px;
}
.copyright-links a {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
}
.copyright-content {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
}
.copyright-content::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 95%;
    background-color: #fff;
    opacity: 0.11;
    left: 0;
    top: 0;
    right: 0;
    margin-inline: auto;
}