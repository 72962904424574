
.wrapper-calender {
    background-attachment: fixed;
    background-image: url("../../assets/images/header-leaf.webp");
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: initial;
    padding-top: 20px;
}
.calender-table {
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 16px;
    box-shadow: 0 4px 24px 0 rgba(0,0,0,.16);
    margin-bottom: 16px;
    overflow: hidden;
}
.calender-filters {
    margin-top: 20px;
}
.search-filter-listing {
    display: flex;
    align-items: center;
}
.search-filter-listing .form-group + .form-group {
    margin-left: 15px;
}
.search-filter-listing .form-group{
    margin-bottom: 0;
}
.search-filter-listing .form-control,.search-filter-listing .form-select {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    min-width: 120px;
    cursor: pointer;
}
.search-filter-listing .form-control:hover,
.search-filter-listing .form-control:active,
.search-filter-listing .form-control:focus {
    outline: 0 none;
}
.filter-products .nav-pills .nav-item + .nav-item {
    margin-left: 16px;
}
.filter-products .nav-pills .nav-item .nav-link {
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    background: #FFF;
    color: #808080;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: center;
}
.filter-products .nav-pills .nav-item .nav-link .fa{
    margin-left: 8px;
    font-size: 18px;
}
.filter-products .nav-pills .nav-item .nav-link.active{
    color: #344A1B;
    border-radius: 12px;
    border: 1px solid #344A1B;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.product-list-view table thead th {
    background-color: #FFFFF0;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.product-list-view table tbody td {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    vertical-align: middle;
}
.product-list-view table tbody td a {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 0;
    min-width: unset;
    padding: 8px 12px;
}
.product-list-view table tbody tr:hover td {
    background-color: #efefef;
}
.product-list-view table thead th,.product-list-view table tbody td{
    padding: 0.75rem 0.75rem;
}
.product-list-view table thead th:first-child, .product-list-view table tbody td:first-child {
    padding-left: 1.5rem;
}