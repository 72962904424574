.swal2-modal .swal2-styled:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.error{
    color: #dc3545;
    font-size: 12px;
}
.program-steps-card {
    border: 1px solid #E4E4E4;
    background-color: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 30px;
}
.step-title {
    display: flex;
    align-items: center;
}
.step-name {
    color: #000;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-left: 12px;
}
.step-number {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: relative;
    z-index: 1;
}
.step-number::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 26px;
    width: 26px;
    border: 2px solid #000;
    background-color: transparent;
    transform: translate(-50%, -50%);
    z-index: 0;
    border-radius: 50%;
}
.booking-page-title{
    color: #000;
    font-size: 28px;
    font-weight: 700;
}
.post-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background-color: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
    margin-top: 80px;
}
.post-banner {
    max-height: 304px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}
.post-banner img {
    transition: all .4s;
}
.post-card:hover .post-banner img {
    transform: scale(1.1);
}
.post-wrap .post-date, .post-wrap .post-location {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 14px 0px;
}
.post-wrap .post-date span, .post-wrap .post-location span {
    margin-right: 8px;
}
.post-price {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}
.post-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 15px;
}
.form-group{
    margin-bottom: 30px;
    position: relative;
}
.form-group .form-label {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    opacity: 0.6;
}
.form-group .form-control {
    border-radius: 8px;
    border: 1px solid #A0A0A0;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.form-group .form-control:hover,
.form-group .form-control:active,
.form-group .form-control:focus{
    outline: 0 none;
    box-shadow: unset;
}
.form-group .form-control::placeholder {
    opacity: 0.5;
}
.pricing-wrap {
    border-radius: 8px;
    background-color: #F3F3F3;
    padding: 8px;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.pricing-wrap.bg-grey {
    background-color: #EAEAEA;
    padding: 15px;
}
.pricing-wrap img {
    border-radius: 8px;
    overflow: hidden;
    max-width: 100px;
    min-width: 100px;
    height: 72px;
}
.pricing-content {
    max-width: calc(100% - 100px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}
.pricing-title {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.post-info-wrap p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 7px;
    opacity: 0.7;
}
.post-info-wrap p + p{
    margin-left: 10px;
}
.post-info-wrap p span{
    display: inline-block;
    margin-right: 5px;
}
.donation-group .form-label {
    position: absolute;
    right: 10px;
    top: 7px;
    color: #000;
    font-size: 18px;
    font-weight: 800;
    line-height: 24px;
    margin: 0;
}
.form-group.donation-group .form-control {
    border: 1px solid #000;
    border-radius: 0;
    margin: 0;
    background-color: transparent;
    padding-right: 30px;
    text-align: right;
}
.donation-title{
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}
.donation-content p {
    color: #000;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}
.form-group.donation-group {
    margin-bottom: 0;
}
.pay-detail-content p {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    max-width: 260px;
}
.deposit-content {
    background-color: #EAEAEA;
}
.due-content {
    background-color: #FFD9D9;
}
.due-content p strong,.deposit-content p strong {
    color: #000;
    font-size: 12px;
    font-weight: 700;
}
.due-content p span,.deposit-content p span {
    color: #000;
    font-size: 18px;
    line-height: 24px;
}
.payment-footer {
    padding: 16px;
    border-top: 2px solid #EAEAEA;
    margin-top: 30px;
}
.terms-text p {
    color: #000;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    max-width: 430px;
}
.action-btns .btn-primary{
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    line-height: 32px;
    width: 174px;
    border-radius: 12px;
    border: 1px solid #344A1B;
    transition: all .4s;
}
.action-btns .btn-primary:first-child {
    background-color: #fff;
    color: #344A1B;
}
.action-btns .btn-primary:last-child {
    background-color: #344A1B;
    color: #FFF;
    margin-left: 20px;
}
.action-btns .btn-primary:first-child:hover,.action-btns .btn-primary:first-child:active,.action-btns .btn-primary:first-child:focus,.action-btns .btn-primary:last-child:hover,.action-btns .btn-primary:last-child:active,.action-btns .btn-primary:last-child:focus{
    background-color: #51712c;
    border-color: #51712c;
    color: #fff;
    outline: 0 none;
}
@media screen and (max-width: 767px){
.post-banner img {
    width: 100%;
}
}
@media screen and (max-width: 575px){
.pricing-content,.post-info-wrap {
    flex-wrap: wrap;
}
.pricing-text, .pricing-label,.post-info-wrap p {
    width: 100%;
}
}
