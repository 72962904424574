.main-header .navbar {
    padding: 5px 0 0px;
    background-color: #fff;
}
.main-header .navbar::after {
    background-image: url("../../assets/images/header-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    left: -15px;
    top: 25px;
    position: absolute;
    height: 100%;
    width: calc(100% + 30px);
    z-index: -1;
    background-position: bottom;
}
.main-header .navbar-brand {
    padding: 0;
}
.main-header .navbar-toggler,
.main-header .navbar-toggler:hover,
.main-header .navbar-toggler:active,
.main-header .navbar-toggler:focus{
    outline: 0 none;
    box-shadow: unset;
    border: medium none;
}
.navbar-brand img {
    max-width: 140px;
}
.main-header .navbar .navbar-nav .nav-link{
    padding: 20px 20px;
}
.main-header .navbar .navbar-nav .nav-link.user-icon {
    margin: 5px 0;
    padding: 7px 10px 15px 10px;
}
.main-header .navbar .navbar-nav .nav-link.notify-icon {
    padding: 2px 3px 2px 3px;
    margin: 10px 0px;
}
.main-header .navbar .navbar-nav .nav-link.user-icon img {
    max-width: 40px;
}
.notify-icon img {
    border-radius: 50%;
    background: #F2F2CD;
    padding: 7px 8px;
    max-width: 30px;
}
.main-header .nav-link {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
}
.main-header .nav-link.active{
    font-weight: bold;
}
.main-header .nav-link::after {
    position: absolute;
    content: "";
    width: 0;
    background-color: #C6C62F;
    left: 0;
    top: -5px;
    height: 4px;
    border-radius: 0 0 4px 4px;
    transition: all.4s;
}
.main-header .nav-link.active::after {
    width: 100%;
}
@media screen and (min-width: 1200px){
.navbar-expand-xl .navbar-nav {
    align-items: center;
}
.main-header .navbar .navbar-nav .nav-link {
    text-align: center;
}
}
@media screen and (min-width: 1200px) and (max-width: 1399.98px){
.main-header .nav-link {
    font-size: 14px;
}
/* .main-header .navbar .navbar-nav .nav-link {
    padding: 20px 4px 20px 4px;
} */
}
@media screen and (min-width: 1200px){
.main-header .navbar .navbar-nav .nav-link:first-child,
.main-header .navbar .navbar-nav .nav-link:last-child{
    margin-left: auto;
}
.main-header .navbar-nav{
    width: 100%;
}
}
@media screen and (max-width: 1200px){
.main-header .navbar {
    padding: 10px 0 10px;
}
.main-header .navbar .navbar-nav .nav-link {
    padding: 20px 5px;
}
.main-header .nav-link::after {
    top: unset;
    bottom: 0;
}
.main-header .navbar-collapse {
    margin-top: 15px;
}
.main-header .navbar::after {
    top: 15px;
    height: 60px;
}
}