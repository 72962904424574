.amazon-section {
    padding-bottom: 100px;
}
.center-page-section {
    margin-top: 100px;
}
.center-image {
    border-radius: 16px;
    overflow: hidden;
}
.center-image img{
    height: auto;
}
.owl-theme.center-page-carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px;
    background: #C6C6C6;
    display: block;
    transition: all .4s ease-in-out;
    border-radius: 30px;
}
.owl-theme.center-page-carousel .owl-dots .owl-dot.active span {
    width: 20px;
    height: 8px;
    background-color: #344A1B;
    border-radius: 8px;
}
.amazon-title {
    color: #000;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    margin: 0;
}
.amazon-title-section p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    max-width: 1080px;
    margin: 0;
}
.amazon-subtitle {
    color: #000;
    font-size: 32px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 52px;
}
.amazon-images {
    position: relative;
}
.amazon-images img{
    height: auto;
}
.bottom-iamge {
    position: absolute;
    right: 0;
    bottom: -120px;
    transform: translateX(-50%);
}
.amazon-text p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px;
}
.amazon-title-section {
    border-bottom: 2px solid #0000001a;
    padding-bottom: 30px;
    margin-bottom: 40px;
}
@media screen and (max-width: 991px){
.bottom-iamge {
    max-width: 70%;
}
}
@media screen and (max-width: 767px){
.bottom-iamge {
    transform: translateX(-25%);
}
.amazon-section .col-md-4:nth-last-child(2) {
    order: 4;
}
.amazon-images {
    margin-bottom: 120px;
}
.amazon-section {
    padding-bottom: 0;
}
.amazon-images .main-iamge {
    margin: auto;
    display: block;
}
}
@media screen and (max-width: 575px){
.amazon-title {
    font-size: 36px;
}
.amazon-subtitle {
    font-size: 26px;
    line-height: 36px;
}
.amazon-title-section {
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.amazon-section {
    padding-bottom: 0px;
}
.bottom-iamge {
    position: relative;
    right: 40px;
    bottom: 40px;
    transform: unset;
}
.amazon-images {
    margin-top: 30px;
    margin-bottom: 0px;
}
}
