
.wrapper-programs{
    background-image: url("../../assets/images/header-leaf.webp");
    background-repeat: no-repeat;
    background-size: initial;
    background-position: right top;
    background-attachment: fixed;
    padding-top: 20px;
}
.program-filters {
    margin-top: 20px;
}
.filter-section {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 2px solid #E9E9E9;
}
.search-filter-listing {
    display: flex;
    align-items: center;
}
.search-filter-listing .form-group + .form-group {
    margin-left: 15px;
}
.search-filter-listing .form-control,.search-filter-listing .form-select {
    border-radius: 8px;
    background-color: #EAEAEA;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    min-width: 120px;
    cursor: pointer;
}
.search-filter-listing .form-control:hover,
.search-filter-listing .form-control:active,
.search-filter-listing .form-control:focus {
    outline: 0 none;
}
.sort-filter-listing .btn {
    border-radius: 8px;
    background-color: #EAEAEA;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid #EAEAEA;
    min-width: 120px;
}
.sort-filter-listing .btn:hover,
.sort-filter-listing .btn:active,
.sort-filter-listing .btn:focus{
    outline: 0 none;
    background-color: #EAEAEA;
    border: 1px solid #EAEAEA;
    color: #000;
}
.sort-filter-listing .btn + .btn{
    margin-left: 15px;
}
.filter-panes {
    padding-bottom: 30px;
}
.filter-panes .nav-pills .nav-item + .nav-item .nav-link{
    margin-left: 15px;
}
.filter-panes .nav-pills .nav-link {
    color: #000;
    font-size: 22px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    opacity: 0.5;
    background-color: #fff;
    position: relative;
    padding: 6px 10px 6px 0;
}
.filter-panes .nav-pills .nav-link.active {
    opacity: 1;
    color: #000;
    background-color: #fff;
}
.filter-panes .nav-pills .nav-link::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #C6C62F;
    border-radius: 4px;
    width: 0;
    transition: all .4s;
}
.filter-panes .nav-pills .nav-link.active::after{
    width: 70px;
}
.programs-content {
    padding-left: 15px;
    padding-top: 80px;
}
.programs-content p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 550px;
}
.programs-wrap {
    padding: 15px;
}
.programs-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    margin-top: 30px;
    display: block;
}
.programs-banner {
    max-height: 304px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}
.programs-banner img {
    transition: all .4s;
    height: auto;
}
.programs-card:hover .programs-banner img {
    transform: scale(1.1);
}
.programs-wrap .programs-date, .programs-wrap .programs-location {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 14px 0px;
}
.programs-wrap .programs-date span, .programs-wrap .programs-location span {
    margin-right: 8px;
    color: #344A1B;
    min-width: 13px;
}
.programs-price {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}
.programs-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.tabs-maps {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tabs-maps .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 22px;
}
.tabs-maps .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
.tabs-maps .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.tabs-maps .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.tabs-maps input:checked + .slider {
    background-color: #2196F3;
}  
.tabs-maps input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}  
.tabs-maps input:checked + .slider:before {
    transform: translateX(23px);
}
  /* Rounded sliders */
.tabs-maps .slider.round {
    border-radius: 34px;
}
.tabs-maps .slider.round:before {
    border-radius: 50%;
}
.switch-maps {
    display: flex;
    align-items: center;
}
.switch-maps .map-text {
    margin-right: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
@media screen and (max-width: 1200px){
.wrapper-programs {
    margin-top: 85px;
}
.filter-section {
    margin-top: 20px;
}
.search-filter-listing {
    padding-bottom: 20px;
    overflow-x: auto;
    margin-bottom: 20px;
}
.sort-filter-listing {
    overflow-x: auto;
    display: flex;
    padding-bottom: 15px;
}
}
@media screen and (max-width: 991px){
.programs-banner img {
    width: 100%;
}
.tabs-maps {
    flex-wrap: wrap;
}
.switch-maps {
    margin-left: auto;
    margin-top: 10px;
}
.tabs-maps .nav-pills {
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: pre;
    padding-bottom: 10px;
}
}
@media screen and (max-width: 767px){
.teams-heading {
    font-size: 44px;
    line-height: 50px;
}
.teams-banner-section {
    padding: 70px 0;
}
}