.product-banner-section{
    padding: 144px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.product-banner-section.master-banner{
    background-image: url("../../assets/images/master-banner.webp");
}
.product-layout-section{
    padding-bottom: 30px;
}
.product-heading.text-center {
    color: #FFF;
    font-size: 64px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 52px;
    margin: 0;
}
.about-product-section {
    padding: 40px 0;
}
.about-product-title {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 52px;
}
.about-product-content p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.search-filter-listing {
    display: flex;
    align-items: center;
}
.search-filter-listing .form-group + .form-group {
    margin-left: 15px;
}
.search-filter-listing .form-group{
    margin-bottom: 0;
}
.search-filter-listing .form-control,.search-filter-listing .form-select {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #D4D4D4;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    min-width: 120px;
    cursor: pointer;
}
.search-filter-listing .form-control:hover,
.search-filter-listing .form-control:active,
.search-filter-listing .form-control:focus {
    outline: 0 none;
}
.filter-products .nav-pills .nav-item + .nav-item {
    margin-left: 16px;
}
.filter-products .nav-pills .nav-item .nav-link {
    border-radius: 12px;
    border: 1px solid #D4D4D4;
    background: #FFF;
    color: #808080;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    align-items: center;
}
.filter-products .nav-pills .nav-item .nav-link .fa{
    margin-left: 8px;
    font-size: 18px;
}
.filter-products .nav-pills .nav-item .nav-link.active{
    color: #344A1B;
    border-radius: 12px;
    border: 1px solid #344A1B;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.product-list-wrap{
    padding: 15px;
}
.product-list-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
}
.product-list-card:hover {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
}
.product-list-banner {
    max-height: 304px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}
.product-list-banner img{
    transition: all .4s;
    width: 100%;
}
.product-list-card:hover .product-list-banner img {
    transform: scale(1.1);
}
.product-list-wrap .product-list-date, .product-list-wrap .product-list-location {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 14px 0px;
}
.product-list-wrap .product-list-date span, .product-list-wrap .product-list-location span {
    color: #344A1B;
    min-width: 13px;
}
.product-list-price {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}
.product-list-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.product-list-view .accordion .accordion-item {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 16px;
}
.product-list-view .accordion .accordion-item .accordion-button {
    color: #000;
    font-size: 18px;
    background-color: #fff;
    box-shadow: unset;
}
.product-list-view .accordion .accordion-item .accordion-body {
    padding: 0;
}
.product-list-view table thead th {
    background-color: #FFFFF0;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.product-list-view table tbody td {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    vertical-align: middle;
}
.product-list-view table tbody tr:hover td {
    background-color: #efefef;
}
.product-list-view table tbody td a {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 0;
    min-width: unset;
    padding: 8px 12px;
}
.product-list-view table thead th,.product-list-view table tbody td{
    padding: 0.75rem 0.75rem;
}
.product-list-view table thead th:first-child, .product-list-view table tbody td:first-child {
    padding-left: 1.5rem;
}
@media screen and (max-width: 767px){
.product-heading.text-center {
    font-size: 46px;
}
.product-banner-section {
    padding: 50px 0;
    background-position: center;
}
.about-product-title {
    font-size: 28px;
    line-height: 32px;
}
}