.banner-section{
    background: url("https://assets.arrellio.com/uploads/1694520351050banner-gif.gif");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center center;
}
.banner-title {
    color: #FFF;
    text-align: center;
    font-size: 100px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 130px;
    letter-spacing: -2px;
    text-transform: uppercase;
    opacity: 0.7;
}
.banner-section .banner-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner-card-content {
    max-width: calc(100% - 150px);
}
.banner-card-image {
    width: 150px;
    overflow: hidden;
    border-radius: 16px;
}
.banner-card-image img {
    transition: all .4s;
}
.banner-card-section:hover .banner-card-image img{
    transform: scale(1.1);
}
.banner-content-wrap {
    padding: 0 10px 0 15px;
}
.banner-card-section {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    max-width: 460px;
    padding: 6px;
    margin-left: auto;
    margin-right: 3vw;
    margin-bottom: 20px;
}
.banner-card-name {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.card-date, .card-location {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
}
.card-date span, .card-location span {
    margin-right: 8px;
    color: #344A1B;
    min-width: 14px;
}
.banner-card-section-wrap {
    position: relative;
    z-index: 1;
    padding: 50px 0 90px 0px;
}
.card-price {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    margin: 0;
}
.book-card a {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background-color: #344A1B;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    line-height: 32px;
    padding: 0 10px;
    transition: all .4s;
}
.book-card a:hover,.book-card a:active,.book-card a:focus {
    background-color: #4c652f;
}
.banner-title-section {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}
.section-title {
    color: #000;
    font-size: 48px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 52px;
}
.title-small{
    color: #000;
    font-size: 36px;
    font-weight: 700;
}
.philosophy-article{
    background-image: url("../../assets/images/philosophy-bg.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -280px -740px;
    padding-top: 50px;
}
.philosophy-content p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.philosophy-image {
    display: inline-block;
    position: relative;
}
.philosophy-image .phil-image {
    position: absolute;
    right: 100%;
    transform: translateX(50%);
    bottom: 0;
}
.team-section{
    background-image: url("../../assets/images/team-bg.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right bottom;
    padding-bottom: 130px;
    padding-top: 50px;
}
.team-card {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
    margin-top: 30px;
    display: block;
}
.team-avtar {
    padding: 70px 20px 10px 20px;
    position: relative;
}
.team-carousel .owl-stage-outer {
    padding-bottom: 30px;
}
.team-section .view-all-btn {
    margin-top: 20px;
}
.owl-carousel .owl-item .team-avtar img,.team-avtar img {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position: absolute;
    top: -25px;
    width: auto;
}
.team-wrap {
    padding: 0 20px 20px 25px;
}
.team-role {
    color: #344A1B;
    font-size: 12px;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
}
.team-member {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
}
.team-info {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.team-info a,.read-more {
    color: #344A1B;
    font-weight: 600;
}
.view-all-btn {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background-color: #344A1B;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    padding: 14px 16px;
    display: inline-block;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    min-width: 150px;
    transition: all .4s;
}
.view-all-btn:hover,.view-all-btn:active,.view-all-btn:focus {
    background-color: #4c6e25;
}
.medicine-section {
    background: linear-gradient(#F0F0F0, #fff);
    padding: 0px 0 60px 0;  
}
.medicine-image img {
    width: 100%;
    height: auto;
}
.medicine-row {
    margin-top: -100px;
}
.medicine-content {
    padding-left: 15px;
    padding-top: 80px;
}
.medicine-content p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 550px;
}

.medicine-wrap {
    padding: 15px;
}
.medicine-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    display: block;
}
.medicine-banner {
    max-height: 304px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
}
.medicine-banner img{
    transition: all .4s;
}
.medicine-card:hover .medicine-banner img {
    transform: scale(1.1);
}
.medicine-wrap .medicine-date, .medicine-wrap .medicine-location {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 14px 0px;
}
.medicine-wrap .medicine-date span, .medicine-wrap .medicine-location span {
    margin-right: 8px;
}
.medicine-price {
    color: #4C4C4C;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}
.medicine-name {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    margin-bottom: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.center-section{
    background-image: url("../../assets/images/center-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0 70px;
}
.center-title-wrap .section-title {
    color: #fff;
}
.center-title-wrap p {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    max-width: 500px;
}
.center-banner {
    position: relative;
    border: 2px solid #CAE3AC;
    border-radius: 16px;
    max-height: 387px;
    overflow: hidden;
}
.center-banner img {
    transition: all .4s;
    height: auto;
    width: 100%;
}
.center-card:hover .center-banner img{
    transform: scale(1.1);
}
.center-flag {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 15px;
    line-height: 24px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #F5F5F5;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    padding: 6px 9px;
    z-index: 1;
}
.center-flag a{
    color: #344A1B;
    font-size: 14px;
    font-weight: 600;
}
.center-name {
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 10px 0 0 0;
}
.center-count {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}
.media-card {
    display: flex;
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
    margin-top: 24px;
}
.media-avtar {
    width: 110px;
    border-radius: 8px;
    overflow: hidden;
}
.media-avtar img {
    width: 100%;
    height: auto;
    transition: all .4s;
}
.media-card:hover .media-avtar img{
    transform: scale(1.1);
}
.media-section {
    padding: 50px 0 70px;
}
.media-top-section p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.media-tabs .nav-pills .nav-link {
    border-radius: 12px;
    border: 1px solid #344A1B;
    margin-right: 16px;
    color: #344A1B;
    font-size: 14px;
    font-weight: 700;
}
.media-content {
    padding-left: 16px;
}
.media-tabs .nav-pills .nav-link:hover,.media-tabs .nav-pills .nav-link.active {
    background: #344A1B;
    color: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
}
.media-title-text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}
.media-view-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.media-view {
    color: #000;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    opacity: 0.6;
}
.media-time {
    border-radius: 8px;
    background: #000;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    padding: 0 5px;
}
.testimonial-section {
    padding: 80px 0 60px;
    background-image: url("../../assets/images/testimonial-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -20px;
}
.testimonial-carousel .owl-stage-outer {
    padding-top: 60px;
    padding-bottom: 30px;
}
.testimonial-carousel .owl-stage {
    display: flex;
}
.testimonial-carousel .item {
    display: flex;
    height: 100%;
}
.testimonial-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background-color: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    position: relative;
}
.testimonial-text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.client-title {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
}
.client-email{
    margin-bottom: 8px;
}
.client-email a {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.testimonial-avtar {
    height: 90px;
    width: 90px;
    position: absolute;
    top: -45px;
}
.testimonial-carousel .owl-stage-outer .owl-item{
    /* padding: 0 15px; */
}
.client-feedback {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    margin: 15px 0 0 0;
    border-top: 2px solid rgba(0, 0, 0, 0.10);
    padding-top: 15px;
    min-height: 148px;
}
.testimonial-overview {
    padding-top: 40px;
    position: relative;
}
.quote-icon {
    position: absolute;
    top: 20px;
    right: 0;
    max-width: 40px;
}
.product-carousel {
    padding: 0px 80px 20px 80px;
    margin-top: -100px;
}
.product-carousel .owl-stage-outer {
    padding-top: 120px;
    padding-bottom: 30px;
}
.product-carousel .owl-dots {
    display: none;
}
.product-carousel .owl-nav {
    margin: 0;
}
.product-carousel .owl-nav .owl-prev,.product-carousel .owl-nav .owl-next {
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}
.product-carousel .owl-nav .owl-prev:hover,.product-carousel .owl-nav .owl-next:hover,
.product-carousel .owl-nav .owl-prev:active,.product-carousel .owl-nav .owl-next:active,
.product-carousel .owl-nav .owl-prev:focus,.product-carousel .owl-nav .owl-next:focus{
    outline: 0 none;
    background-color: transparent;
    box-shadow: unset;
}
.product-carousel .owl-nav .owl-prev span,.product-carousel .owl-nav .owl-next span{
    font-size: 40px;
    color: #4A4C4D;
}
.product-carousel .owl-nav .owl-prev {
    left: 20px;
}
.product-carousel .owl-nav .owl-next {
    right: 20px;
}
.product-card {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    position: relative;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
}
.product-info-title {
    background-color: #313130;
    position: relative;
    height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-info-title img {
    position: absolute;
    max-height: 330px;
    top: 20px;
    left: 0;
}
.product-info-text {
    background-color: #D9D9D9;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.product-text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 15px 40px;
    max-width: 580px;
}
.product-title-main {
    color: #FFF;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    max-width: 410px;
}
.product-title {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: 250px;
}
.product-image {
    position: relative;
    padding-bottom: 150px;
}
.product-image img {
    position: absolute;
    max-width: 280px;
    top: -120px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
}
.product-overview {
    padding: 16px;
}
.product-price {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.article-top-section p {
    color: #000;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}
.article-listing-row .col-sm-12:first-child .article-main-content {
    position: absolute;
    bottom: 0;
    padding: 20px;
}
.article-card {
    position: relative;
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    max-height: 372px;
    overflow: hidden;
    margin-top: 30px;
}
.article-image {
    height: 100%;
    max-height: 372px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
}
.article-image img{
    transition: all .4s;
    width: 100%;
    height: auto;
}
.article-card:hover .article-image img{
    transform: scale(1.1);
}
.article-listing-row .col-sm-12:first-child .article-main-content .article-date {
    display: none;
}
.article-main-content {
    padding: 0 15px 15px 15px;
}
.article-cat-title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 24px;
}
.article-listing-row .col-sm-12:first-child .article-cat-title{
    color: #FFF;
    font-size: 16px;
}
.article-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}
.article-listing-row .col-sm-12:first-child .article-text{
    color: #FFF;
    opacity: 0.8;
}
.article-date {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0;
}
.article-section {
    padding: 30px 0 80px;
}
/* Responsive Design CSS Start */
@media screen and (max-width: 1400px){
.medicine-row {
    margin-top: -50px;
}
}
@media screen and (min-width: 767.98px) and (max-width: 991px){
.book-card{
    flex-wrap: wrap;
}
.card-price {
    width: 100%;
}
.banner-title {
    font-size: 42px !important;
}
}
@media screen and (max-width: 1199.98px){
.medicine-row {
    margin-top: -40px;
}
.banner-title {
    font-size: 54px;
    line-height: 80px;
}
.main-header .nav-link br {
    display: none;
}
}
@media screen and (max-width: 991.98px){
.banner-title {
    font-size: 50px;
    line-height: 60px;
}
.medicine-row {
    margin-top: 0px;
}
.medicine-card {
    margin-top: 30px;
}
.product-info-title {
    padding-left: 20px;
}
.article-card, .article-image {
    max-height: unset;
}
.footer-info {
    margin-top: 40px;
}
.philosophy-image {
    max-width: 70%;
    height: auto;
}
.footer-links {
    display: flex;
    justify-content: space-between;
}
}
@media screen and (max-width: 767.98px){
.section-title {
    font-size: 36px;
    line-height: 40px;
}
.philosophy-article {
    padding-top: 60px;
}
.banner-title-section {
    position: relative;
    top: unset;
    transform: unset;
    padding-top: 70px;
}
.banner-card-section {
    margin-inline: auto;
    margin-bottom: 0px;
    width: 90%;
    background: #fff;
}
.banner-slides {
    margin-bottom: -90px;
}
.banner-section {
    margin-bottom: 30px;
}
.banner-carousel .owl-stage-outer {
    padding-bottom: 30px;
}
.philosophy-image .phil-image {
    right: 80%;
    bottom: -40px;
}
.philosophy-image img{
    height: auto;
}
.philosophy-image {
    margin-bottom: 50px;
    margin-top: 40px;
    max-width: 90%;
}
.team-section {
    padding-bottom: 50px;
}
.medicine-image img {
    width: 100%;
}
.center-card {
    margin-top: 30px;
}
.product-carousel {
    padding: 0px 30px;
    margin-top: 50px;
}
.product-text {
    margin-inline: auto;
}
.copyright-links {
    justify-content: center;
}
.copyright-text p {
    margin: 0 0 15px 0;
    text-align: center;
}
.product-info-title {
    height: 240px;
}
.product-carousel .owl-nav .owl-prev {
    left: 0px;
}
.product-carousel .owl-nav .owl-next {
    right: 0px;
}
.team-carousel .owl-item .team-avtar img {
    box-shadow: unset;
}
.team-carousel .owl-item.active .team-avtar img {
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
}
.team-carousel .owl-item.active .team-card {
    border: 1px solid #344A1B;
}
.team-carousel .owl-item .team-avtar img {
    margin: auto;
    height: 140px;
    width: 140px;
}
.team-avtar {
    padding: 100px 20px 10px 20px;
}
.team-wrap {
    text-align: center;
}
.banner-card-section-wrap {
    padding: 10px 0 0px 0px;
}
.medicine-content {
    padding-top: 30px;
}
.team-section {
    padding-top: 30px;
}
.media-section {
    padding: 50px 0 30px;
}
.media-avtar img {
    height: 100%;
    object-fit: cover;
}
}
@media screen and (max-width:575.98px){
.center-carousel .owl-stage {
    padding-left: 0 !important;
}
.banner-card-name {
    font-size: 14px;
}
.banner-card-content {
    max-width: calc(100% - 100px);
}
.center-flag {
    line-height: 20px;
    border-radius: 6px;
    padding: 2px 6px;
}
.center-flag a {
    font-size: 12px;
    right: 10px;
    top: 10px;
}
}
@media screen and (max-width: 480.98px){
.book-card{
    flex-wrap: wrap;
}
.card-price {
    width: 100%;
    line-height: 20px;
}
.philosophy-image .phil-image {
    right: 72%;
    bottom: 10px;
}
.banner-card-image {
    width: 112px;
}
.card-date, .card-location {
    font-size: 10px;
}
.book-card a {
    line-height: 30px;
}
.banner-card-section {
    width: 100%;
}
}
@media screen and (max-width: 414.98px){
.banner-card-name {
    font-size: 11px;
}
.philosophy-image {
    margin-bottom: 0px;
}
}