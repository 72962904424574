.maestro-wrapper {
    border-radius: 12px;
    background-color: #C6C62F;
    padding: 15px 80px;
    position: relative;
    left: -50px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: calc(100% + 50px);
    z-index: -1;
}
.wrapper-team-detail {
    padding-bottom: 30px;
}
.team-detail-avtar {
    border-radius: 12px;
    border: 4px solid #C6C62F;
    padding: 15px 8px 8px 8px;
    margin-top: -13px;
}
.team-detail-avtar img{
    width: 100%;
    height: auto;
}
.team-detail-text  .maestro-wrapper p {
    color: #344A1B;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
}
.maestro-wrapper h3 {
    color: #000;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    margin: 0;
}
.team-detail-text p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}
.maestro-text-wrap {
    padding-left: 30px;
}
@media screen and (max-width: 767px){
.team-detail-avtar {
    padding: 8px 8px 8px 8px;
    margin-top: 25px;
}
.maestro-wrapper {
    padding: 15px 20px;
    margin-top: 30px;
    width: 100%;
    left: 0;
}
.maestro-text-wrap {
    padding-left: 10px;
}
.maestro-wrapper h3 {
    font-size: 32px;
}
.teams-banner-section {
    padding: 70px 0;
}
.teams-heading {
    font-size: 36px;
    line-height: 40px;
}
}