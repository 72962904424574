.wrapper {
  margin-top: 64px;
  max-width: 1920px;
  margin-inline: auto;
}
.back-wrap .btn-primary {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  background-color: transparent;
  border: medium none;
  padding: 0;
  opacity: 0.6;
  display: flex;
  align-items: center;
}
.back-wrap .btn-primary .fa{
  margin-right: 5px;
}
/* Import Fonts */
@font-face {
  font-family: 'Josefin Sans';
  src: url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.eot');
  src: url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.woff2') format('woff2'),
      url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.woff') format('woff'),
      url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.ttf') format('truetype'),
      url('././assets/fonts/Josefin_Sans/JosefinSans-Bold.svg#JosefinSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter/Inter-ExtraBold.eot');
  src: url('././assets/fonts/Inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Inter/Inter-ExtraBold.woff2') format('woff2'),
      url('././assets/fonts/Inter/Inter-ExtraBold.woff') format('woff'),
      url('././assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype'),
      url('././assets/fonts/Inter/Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter/Inter-Regular.eot');
  src: url('././assets/fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Inter/Inter-Regular.woff2') format('woff2'),
      url('././assets/fonts/Inter/Inter-Regular.woff') format('woff'),
      url('././assets/fonts/Inter/Inter-Regular.ttf') format('truetype'),
      url('././assets/fonts/Inter/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter/Inter-Medium.eot');
  src: url('././assets/fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Inter/Inter-Medium.woff2') format('woff2'),
      url('././assets/fonts/Inter/Inter-Medium.woff') format('woff'),
      url('././assets/fonts/Inter/Inter-Medium.ttf') format('truetype'),
      url('././assets/fonts/Inter/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter/Inter-Bold.eot');
  src: url('././assets/fonts/Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Inter/Inter-Bold.woff2') format('woff2'),
      url('././assets/fonts/Inter/Inter-Bold.woff') format('woff'),
      url('././assets/fonts/Inter/Inter-Bold.ttf') format('truetype'),
      url('././assets/fonts/Inter/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: url('././assets/fonts/Inter/Inter-SemiBold.eot');
  src: url('././assets/fonts/Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('././assets/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
      url('././assets/fonts/Inter/Inter-SemiBold.woff') format('woff'),
      url('././assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype'),
      url('././assets/fonts/Inter/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

