.teams-banner-section{
    padding: 144px 0;
    background-image: url("../../assets/images/Ayahuasca-team.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.product-layout-section{
    padding-bottom: 30px;
}
.teams-heading {
    color: #FFF;
    font-size: 64px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 52px;
    margin: 0;
}
@media screen and (max-width: 767.98px){
.product-heading.text-center {
    font-size: 46px;
}
.team-section .team-wrap {
    text-align: left;
    margin-top: 30px;
}
}