.detail-section{
    background-image: url("../../assets/images/header-leaf.webp");
    background-repeat: no-repeat;
    background-size: initial;
    background-position: right top;
    background-attachment: fixed;
    padding-top: 20px;
}
.detail-meta {
    display: flex;
    align-items: center;
}
.detail-page-title a{
    color: #000;
    font-size: 28px;
    font-weight: 700;
}
.each-meta span{
    color: #000;
    font-size: 14px;
}
.each-meta span:first-child {
    color: #344A1B;
    font-size: 14px;
}
.each-meta{
    margin-bottom: 20px;
}
.each-meta + .each-meta{
    margin-left: 20px;
}
.each-meta span + span {
    margin-left: 8px;
}
.each-meta:last-child span + span{
    text-decoration: underline;
}
.detail-flags {
    display: flex;
    align-items: center;
}
.each-flag {
    display: flex;
    flex-direction: column;
    align-items: center;;
    margin-top: 20px;
}
.each-flag a {
    border-radius: 8px;
    background: #C8D8F8;
    color: #3F5F9E;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 12px;
}
.each-flag + .each-flag{
    margin-left: 12px;
}
.each-flag span {
    color: #000;
    font-size: 10px;
    margin-top: 5px;
}
.detail-sub-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
}
.detail-sub-text {
    color: #000;
    font-size: 14px;
    line-height: 20px;
    max-width: 730px;
}
.detail-image-wrap a{
    display: inline-block;
}
.detail-image-wrap img,.home-location img,.retreat-video img{
    height: auto;
}
.hr {
    opacity: 0.10000000149011612;
    background: #000;
    height: 2px;
    width: 100%;
    margin: 20px 0;
}
.detail-sub-section .read-more {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-decoration: underline
}
.detail-testimonial {
    border-radius: 16px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    margin-bottom: 30px;
}
.username-detail {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
}
.useremail-detail {
    color: #000;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}
.user-avtar img {
    height: 48px;
    width: 48px;
}
.user-info-detail {
    padding-left: 15px;
}
.booking-section {
    border-radius: 24px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
    padding: 20px;
    position: relative;
    height: 100%;
    overflow-y: hidden;
    padding-bottom: 160px;
}
.booking-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
.booking-step .booking-title {
    font-size: 14px;
    opacity: 0.7;
}
.booking-step .form-select {
    border-radius: 16px;
    border: 1px solid #A0A0A0;
    background-color: #FFF;
    padding: 8px 30px 8px 15px;
    color: #000;
    font-size: 16px;
    line-height: 32px;
}
.booking-step .form-select:hover,.booking-step .form-select:active,.booking-step .form-select:focus{
    outline: 0 none;
    box-shadow: unset;
}
.booking-step .rooms-radio-btn {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
}
.room-select-radio {
    border-radius: 16px;
    border: 1px solid #000;
    background: #F6F6F6;
    padding: 15px 15px;
    margin-top: 15px;
}
.custom-check {
    margin-right: 7px;
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
}
.custom-radio-title{
    padding-left: 16px;
}
.custom-radio-title p {
    color: #000;
    font-size: 14px;
    margin: 0;
}
.custom-radio-title h6 {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}
.booking-step .room-select-radio input[type=radio] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkbox-icon{
    width: 20px;
    height: 20px;
    border: 2px solid #000;
    background-color: #fff;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    transition: all .4s;
    min-width: 20px;
}
.checkbox-icon::after{
    width: 10px;
    height: 10px;
    position: absolute;
    content: "";
    background-color: #FFF;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.booking-step  input:checked+ .rooms-radio-btn .checkbox-icon::after {
    background-color: #000;
}
.booking-footer {
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 0 0 24px 24px;
    padding: 24px 16px;
}
.booking-footer .btn-primary {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #344A1B;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    width: 100%;
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    padding: 10px 15px;
}
.booking-footer .btn-primary:hover,
.booking-footer .btn-primary:active,
.booking-footer .btn-primary:focus{
    outline: 0 none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #243017;
}
.refund-link {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    text-decoration-line: underline;
    display: inline-block;
    margin-top: 20px;
}
.terms-title {
    font-weight: 500;
}
.terms-section .more-terms {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    background: #344A1B;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    display: inline-block;
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    padding: 10px 20px;
}
@media screen and (min-width: 992px){
.scrolled.booking-section {
    position: fixed;
    top: 70px;
}
.each-flag a {
    min-width: 124px;
    text-align: center;
}
.booking-section {
    max-height: calc(100vh - 200px);
    overflow-y: hidden;
    padding-bottom: 160px;
    width: 320px;
}
.booking-step.room-step {
    max-height: calc(100% - 110px);
    overflow-y: auto;
}
.scroll-smooth.room-step::-webkit-scrollbar{
	width: 6px;
}
.scroll-smooth.room-step::-webkit-scrollbar-thumb{
	background-color: #aaa;
}
}
@media screen and (min-width: 1200px){
.booking-section{
    width: 356px;
}
}
@media screen and (min-width: 1400px){
.booking-section{
    width: 416px;
}
}
@media screen and (max-width: 991.98px){
.booking-section{
    margin: 30px 0;
}
.retreat-title{
    margin-top: 60px;
}
}